export default {
  validEmpty(rule, value, callback) {
    if( (!value && value != 0) || (value && typeof value == 'string' && !value.trim()) ) return callback(new Error(rule.message))
    else return callback()
  },

  validEmpty1(rule, value, callback) {
    if( !value || (value && typeof value == 'string' && !value.trim()) ) return callback(new Error(rule.message))
    else return callback()
  },

  validLength(rule, value, callback) {
    if(value) {
      const max = +rule.max;
      const min = +rule.min || 1;
      if(max <= 0) return callback();
      if(value.length < min || value.length > max) {
        return callback(new Error(rule.message));
      } else {
        return callback();
      }
    }else {
      callback();
    }

  },
  validateEmail(rule, value, callback) {
    let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if(value) {
      if(!reg.test(value)) {
        return callback(new Error('请输入正确的电子邮箱'));
      }else if(value.length > 50 || value.length < 6) {
        return callback(new Error('请输入6-50个字符以内的电子邮箱'));
      }else {
        return callback();
      }
    }else {
      return callback();
    }
  },
  
  validPhone(rule, value, callback) {
    let isMob = /^((\+?86)|(\(\+86\)))?(1[3|4|5|6|7|8|9][0-9]{9})$/;
    if(value) {
      if(isMob.test(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号码'));
      }
    }else {
      return callback()
    }
  },
  
  validateEmailAndPhone(rule, value, callback) {
    let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    let isMob = /^((\+?86)|(\(\+86\)))?(1[3|4|5|6|7|8|9][0-9]{9})$/;
    if(value) {
      if(value.indexOf('@') != -1) {
        if(value) {
          if(!reg.test(value)) {
            return callback(new Error('请输入正确的电子邮箱'));
          }else if(value.length > 50 || value.length < 6) {
            return callback(new Error('请输入6-50个字符以内的电子邮箱'));
          }else {
            return callback();
          }
        }else {
          return callback();
        }
      }else {
        if(value) {
          if(isMob.test(value)) {
            return callback();
          } else {
            return callback(new Error('请输入正确的手机号码'));
          }
        }else {
          return callback()
        }
      }
    }
  },

  vaildPhoneHk(rule, value, callback) {
    let phoneRegHK = /^([5|6|8|9])\d{7}$/;
    let phoneRegMO = /^[0][9]\d{8}$/;
    if(value) {
      if(phoneRegHK.test(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号码'));
      }
    }else {
      return callback()
    }
  },
  vaildPhoneMo(rule, value, callback) {
    let phoneRegMO = /^[0][9]\d{8}$/;
    if(value) {
      if(phoneRegMO.test(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号码'));
      }
    }else {
      return callback()
    }
  },
  validContactPhone(rule, value, callback) {
    let isPhone = /^([0-9]{3,4}-)[0-9]{7,8}$/;
    let isMob = /^((\+?86)|(\(\+86\)))?(1[3|4|5|6|7|8|9][0-9]{9})$/;
    if(value) {
      if(isPhone.test(value) || isMob.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的联系电话'));
      }
    }else {
      callback();
    }

  },
  validCode(rule, value, callback) {
    let code =  /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if(value) {
      if(code.test(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的身份证号码'));
      }
    }else {
      return callback()
    }
  },
  validnNumber(rule, value, callback) {
    let code =  /^[1-9]\d*$/;
    if(value) {
      if(code.test(value)) {
        return callback();
      } else {
        return callback(new Error('请输入数字'));
      }
    }else {
      return callback()
    }
  },
  validateUserName: (rule, value, callback) => {
    if(!value) {
      return callback(new Error('账号不能为空'));
    } else {
      var reg = /^\w{4,20}$/
      if(reg.test(value)) {
        callback();
      }else {
        callback(new Error('账号支持数字、大写字母、小写字母、下划线组合，4-20位字符'));
      }
    }
  },
  validatePassword: (rule, value, callback) => {
    if(!value) {
      return callback(new Error('请输入密码'));
    } else {
      var reg = /^[A-Za-z0-9]{6,16}$/;
      if(!value.trim() || value.length < 6 || value.length > 16) {
        callback(new Error('请输入正确的密码'));
      }else {
        callback();
      }
    }
  },
  validatePassword1: (rule, value, callback) => {
    if(!value) {
      return callback(new Error('请输入密码'));
    } else {
      var reg = /^[A-Za-z0-9]{6,16}$/;
      if(!value.trim() || value.length < 6 || value.length > 16 || !reg.test(value)) {
        callback(new Error('请按规则设置密码，请重新输入'));
      }else {
        callback();
      }
    }
  },
  validUnit(rule, value, callback) {
    if(!value) {
      callback(new Error('输入单位名称'));
    } else if(value && !value.trim()) {
      callback(new Error('输入单位名称，注意不能输入全部空格'));
    } else if(value.length > 50) {
      callback(new Error('请输入单位名称不超过50个字符'));
    } else {
      callback();
    }
  },
  validSCode(rule, value, callback) {
    if(!value) {
      callback(new Error('输入统一社会信用代码'));
    } else if(value && !value.trim()) {
      callback(new Error('输入统一社会信用代码，注意不能输入全部空格'));
    } else if(value.length > 18) {
      callback(new Error('输入统一社会信用代码不超过18个字符'));
    } else {
      callback();
    }
  },
  validContact(rule, value, callback) {
    if(!value) {
      callback(new Error('输入联系人'));
    } else if(value && !value.trim()) {
      callback(new Error('输入联系人，注意不能输入全部空格'));
    } else {
      callback();
    }
  },
  commonval: (rule, value, callback) => {
    if(!value || !value.trim()) {
      if(rule.field == 'title') {
        return callback(new Error('请输入留言标题'));
      }else if(rule.field == 'content') {
        return callback(new Error('请输入留言内容'));
      }else if(rule.field == 'username') {
        return callback(new Error('请输入您的姓名'));
      }else if(rule.field == 'teamName') {
        if(value.length > 50) {
          return callback(new Error('您的团队名称不能超过50和字符'));
        }else return callback(new Error('请输入您的团队名称'));
      }
    }else {
      callback();
    }
  },
}
